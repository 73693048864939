import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './ContactForm.css';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        agreement: false
    });

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.agreement) {
            emailjs.sendForm('service_xhhhu1s', 'template_wewop71', e.target, '0_DoacKLe1V0OkxP3')
                .then((result) => {
                    alert('Message Sent!');
                }, (error) => {
                    alert('An error occurred, please try again.');
                });
        } else {
            alert('You must agree to the terms and conditions.');
        }
    };

    return (
        <section id="contact" className="contact-form" data-aos="fade-up">
            <h2>Contact Me</h2>
            <form onSubmit={handleSubmit}>
                <input type="text" name="name" placeholder="ФИО" value={formData.name} onChange={handleChange} required />
                <input type="tel" name="phone" placeholder="Номер телефона (+7)" value={formData.phone} onChange={handleChange} required />
                <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                <label>
                    <input type="checkbox" name="agreement" checked={formData.agreement} onChange={handleChange} />
                    <p>Я даю согласие на обработку персональных данных</p>
                </label>
                <button type="submit">Send</button>
            </form>
        </section>
    );
};

export default ContactForm;
