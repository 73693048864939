import React, { useState } from 'react';
import './BurgerMenu.css';

const BurgerMenu = ({ links, scrollToSection }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleLinkClick = (event, href) => {
        setIsOpen(false);
        scrollToSection(event, href);
    };

    return (
        <div className="burger-menu">
            <div className={`burger-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <div className="line1"></div>
                <div className="line2"></div>
                <div className="line3"></div>
            </div>
            <nav className={`menu ${isOpen ? 'open' : ''}`}>
                <button className="close-button" onClick={toggleMenu}>&times;</button>
                <ul>
                    {links.map((link, index) => (
                        <li key={index}>
                            <a href={link.href} onClick={(e) => handleLinkClick(e, link.href.substring(1))}>
                                {link.label}
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
};

export default BurgerMenu;
