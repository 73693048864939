import React from 'react';
import './AboutMe.css';
import profilePic from './../image/me.jpg'; // Убедитесь, что путь к фото правильный

const AboutMe = () => {
    return (
        <section id="about-me" className="about-me">
            <div className="about-me-container">

                <h2 data-aos="fade-down">About Me</h2>
                <div className="about-me-content">
                    <div data-aos="fade-right" className="about-me-text">
                        <p className="about-me-intro">
                            Привет! Меня зовут Фролов Богдан Алексеевич, мне 19 лет.
                        </p>
                        <p className="about-me-details">
                            С 13 лет я развиваюсь в сфере IT. С 16-17 лет я сосредоточился на UI/UX и SEO.
                            Работал в основном на фрилансе, а также в нескольких студиях.
                        </p>
                        <p className="about-me-details">
                            Был руководителем проектов в ABrosko, где управлял тремя командами:
                            SEO, дизайн и верстка. Я многозадачный, люблю работать и считаю себя самоучкой.
                            Почти закончил курсы в yudaev.school, освоив все необходимые навыки по дизайну.
                        </p>
                        <p className="about-me-details">P.S: Дизайн и сайт разработал я, React.js взят за основу</p>
                    </div>
                    <div data-aos="fade-left" className="about-me-photo">
                        <img src={profilePic} alt="Profile" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutMe;
