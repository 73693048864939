import React, { useEffect } from 'react';
import Header from './components/Header';
import MainSection from './components/MainSection';
import Features from './components/Features';
import Works from './components/Works';
import FAQ from './components/FAQ';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AboutMe from "./components/AboutMe";
import WorksPage from "./components/WorksPage";
import Project1Page from "./components/Project-page";
import Popup from "./components/Popup";

const App = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
    <Router>
        <div className="App">
            <Popup message="Уважаемый Клиент, чтобы вернуться на предыдущую страницу, нажмите на Логотип!" />
            <Header />
            <Routes>
                <Route path="/" element={
                    <>
                        <MainSection />
                        <AboutMe/>
                        <Features />
                        <Works />
                        <FAQ />
                        <ContactForm />
                    </>
                } />

                <Route path="/projects" element={<WorksPage />} />
                <Route path="/project/:id" element={<Project1Page />} />
            </Routes>
            <Footer />
        </div>
    </Router>
    );
};

export default App;
