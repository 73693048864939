import React from 'react';
import './Works.css';
import {NavLink} from "react-router-dom";

const Works = () => (
    <section id="cases" className="works" data-aos="fade-up">
        <h2>MY PORTFOLIO</h2>
        <div className="works-grids">
            <div className="work-item2" data-aos="fade-up"><p>About.me - сайт визитка для 3D художницы</p></div>

            <div className="works-grid">
                <NavLink to="/projects" className="work-item1" data-aos="fade-up" data-aos-delay="100">
                    <p>FB DESIGN AGENCY - Дипломная работа для студента онлайн школы (1 Вариант - итоговый, оцентка
                        96/100)</p>
                </NavLink>
                <NavLink to="/projects" className="work-item" data-aos="fade-up" data-aos-delay="200">
                    <p>FB DESIGN AGENCY - Дипломная
                        работа
                        для студента онлайн школы (2 Вариант)</p>
                </NavLink>
            </div>
        </div>
        <NavLink to="/projects" className="cases-btn" data-aos="fade-up" data-aos-delay="200">
            Показать еще
        </NavLink>
    </section>
);

export default Works;
