import React from 'react';
import './Features.css';
import FeatureItem from './FeatureItem';

const Features = () => {
    return (
        <section id="features" className="features">
            <div className="features-container" data-aos="fade-up">
                <div className="features-header" data-aos="fade-right">
                    <h2>SKILLS</h2>
                    <p>5 YEARS</p>
                    <div className="arrow45deg"></div>
                </div>
                <div className="features-grid" data-aos="fade-left">
                    <FeatureItem
                        title="HTML/CSS"
                        description="Специализируюсь на создании семантично правильной структуры страниц и привлекательного оформления с использованием HTML и CSS. Обеспечиваю кроссбраузерную совместимость и адаптивность под любые устройства."
                        icon="./cube.png"
                        data-aos="zoom-in"
                    />
                    <FeatureItem
                        title="JavaScript"
                        description="Использую JavaScript для добавления интерактивности на веб-страницы. Опыт работы с популярными библиотеками и фреймворками, такими как React и jQuery, позволяет мне создавать динамичные и отзывчивые интерфейсы."
                        icon="./js.png"
                        data-aos="zoom-in"
                    />
                    <FeatureItem
                        title="React"
                        description="Разрабатываю сложные и высокопроизводительные пользовательские интерфейсы с использованием React. Уверенно работаю с компонентами, состоянием и хуками для создания современных одностраничных приложений."
                        icon="./react.png"
                        data-aos="zoom-in"
                    />
                    <FeatureItem />
                    <FeatureItem
                        title="UI/UX Дизайн"
                        description="Проектирую интуитивно понятные и визуально привлекательные интерфейсы, обеспечивающие отличный пользовательский опыт. Использую современные инструменты дизайна, такие как Figma и Adobe XD."
                        icon="./figma.png"
                        data-aos="zoom-in"
                    />
                    <FeatureItem
                        title="SEO Оптимизация"
                        description="Опыт в оптимизации сайтов для поисковых систем, улучшение видимости и рейтинга сайта. Применяю передовые практики SEO, включая оптимизацию контента и структуры сайта."
                        icon="./seo.png"
                        data-aos="zoom-in"
                    />
                </div>
            </div>
        </section>
    );
};

export default Features;
