import React from 'react';
import './Header.css';
import { useNavigate } from 'react-router-dom';
import BurgerMenu from './BurgerMenu';

const Header = () => {
    const scrollToSection = (event, id) => {
        event.preventDefault();
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const links = [
        { href: '#about-me', label: 'About' },
        { href: '#features', label: 'Skills' },
        { href: '#cases', label: 'Cases' },
        { href: '#contact', label: 'Contact' },
    ];

    const navigate = useNavigate();
    return (
        <header className="header">
            <div>
                <nav>
                    <BurgerMenu links={links} scrollToSection={scrollToSection} />
                    <ul className="nav-left">
                        <li><a href="#about-me" onClick={(e) => scrollToSection(e, 'about-me')}>About</a></li>
                        <li><a href="#features" onClick={(e) => scrollToSection(e, 'features')}>Skills</a></li>
                    </ul>
                    <div className="logo" onClick={() => navigate(-1)}>NB</div>
                    <ul className="nav-right">
                        <li><a href="#cases" onClick={(e) => scrollToSection(e, 'cases')}>Cases</a></li>
                        <li><a href="#contact" onClick={(e) => scrollToSection(e, 'contact')}>Contact</a></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;
