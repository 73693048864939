import React from 'react';
import './FeatureItem.css';

const FeatureItem = ({ title, description, icon, dataAos }) => {
    return (
        <div className="feature-item" data-aos={dataAos}>
            <div className="feature-icon">
                <img src={icon} alt="feature-icon" />
            </div>
            <div className="feature-content">
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
        </div>
    );
};

export default FeatureItem;
