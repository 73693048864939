import React, { useEffect, useState } from 'react';
import './MainSection.css';

const MainSection = () => {
    const [scrollY, setScrollY] = useState(0);

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <section id="main" className="main-section" style={{ transform: `translateY(-${scrollY * 0.5}px)`, opacity: 1 - scrollY / 400 }}>
            <video autoPlay loop muted className="background-video">
                <source src={process.env.PUBLIC_URL + "/video.mp4"} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="main-content">
                <div className="main-text">
                    <h1>
                        WE ARE <br />
                        WEB-SERVICE <br />
                        <span>AGENCY</span>
                    </h1>
                    <p className="subtext">The first full-stack Web3 creative agency integrating AI technology to deliver best-in-class client experience.</p>
                </div>
                <div className="scroll-down">
                    <span>SCROLL TO EXPLORE</span>
                    <div className="scroll-icon">&#8595;</div>
                </div>
            </div>
        </section>
    );
};

export default MainSection;
