import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './WorksPage.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

const WorksPage = () => {
    const [activeSection, setActiveSection] = useState('design');
    const [isAnimating, setIsAnimating] = useState(false);
    const navigate = useNavigate();

    const worksData = {
        design: {
            bigCard: (
                <div
                    className="big-work-item design-big-card"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    onClick={() => navigate('/project/1')}
                >
                    <p>FB DESIGN STUDIO - Большой проект</p>
                </div>
            ),
            smallCards: [
                "Проект 1",
                "Проект 2",
                "Проект 3",
                "Проект 4",
                "Проект 5",
                "Проект 6",
                "Проект 7",
                "Проект 8"
            ].map((project, index) => (
                <div
                    className={`work-item design-card-${index}`}
                    data-aos="fade-up"
                    data-aos-delay={100 + index * 100}
                    key={index}
                    onClick={() => navigate(`/project/${index + 2}`)}
                >
                    <p>FB DESIGN STUDIO - {project}</p>
                </div>
            )),
        },
        development: {
            bigCard: (
                <div
                    className="big-work-item dev-big-card"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    onClick={() => navigate('/project/10')}
                >
                    <p>FB DESIGN STUDIO - Большой проект</p>
                </div>
            ),
            smallCards: [
                "Проект 9",
                "Проект 10",
                "Проект 11",
                "Проект 12",
                "Проект 13",
            ].map((project, index) => (
                <div
                    className={`work-item dev-card-${index}`}
                    data-aos="fade-up"
                    data-aos-delay={100 + index * 100}
                    key={index}
                    onClick={() => navigate(`/project/${index + 11}`)}
                >
                    <p>FB DEV STUDIO - {project}</p>
                </div>
            )),
        },
    };

    useEffect(() => {
        if (!isAnimating) {
            AOS.refresh();
        }
    }, [activeSection, isAnimating]);

    const handleSectionChange = (section) => {
        setIsAnimating(true);
        setTimeout(() => {
            setActiveSection(section);
            AOS.refreshHard(); // Принудительное обновление AOS
            setIsAnimating(false);
        }, 300); // Длительность анимации скрытия
    };

    const { bigCard, smallCards } = worksData[activeSection];

    return (
        <div className="works-page">
            <header className="works-header">
                <h1>Услуги</h1>
                <nav className="works-nav">
                    <button
                        className={`nav-button ${activeSection === 'design' ? 'active' : ''}`}
                        onClick={() => handleSectionChange('design')}
                    >
                        Первая страница
                    </button>
                    <button
                        className={`nav-button ${activeSection === 'development' ? 'active' : ''}`}
                        onClick={() => handleSectionChange('development')}
                    >
                        Вторая страница
                    </button>
                </nav>
            </header>
            <div className={`works-content ${isAnimating ? 'fade-out' : 'fade-in'}`}>
                {bigCard}
                <div className="works-grid">{smallCards}</div>
            </div>
        </div>
    );
};

export default WorksPage;
