import React from 'react';
import './Footer.css';
import {useNavigate} from "react-router-dom";

const Footer = () => {
    const scrollToSection = (event, id) => {
        event.preventDefault();
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior: 'smooth'});
        }
    };     const navigate = useNavigate();
    return (
        <footer className="footer">
            <div>
                <nav>
                    <ul className="nav-left">
                        <li><a href="#about-me" onClick={(e) => scrollToSection(e, 'about-me')}>About</a></li>
                        <li><a href="#cases" onClick={(e) => scrollToSection(e, 'cases')}>Portfolio</a></li>
                    </ul>
                    <div className="logo" onClick={() => navigate(-1)}>NB</div>
                    <ul className="nav-right">
                        <li><a href="https://www.fl.ru/users/frolovbogdann/portfolio/"><img src="./fl.png" alt="FL.ru" className="fl"/></a></li>
                        <li><a href="mailto:frolov.bogdann@yandex.com"><img src="./mail.webp" alt="Email" className="mail"/></a></li>
                        <li><a href="https://t.me/STaFFuRiA"><img src="./tg.png" alt="Telegram" className="tg"/></a></li>
                    </ul>
                </nav>
            </div>
        </footer>
    );
}

export default Footer;
