import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ProjectPage.css'; // Добавьте стили для проекта
import Popup from './Popup';



import Image1 from '../image/Case.png';
import Image2 from '../image/News - Новости.png';
import Image3 from '../image/News - Отзывы.png';
import Image4 from '../image/News - статьи.png';
import Image5 from '../image/MacBook Pro 16_ - 7.png';
import Image6 from '../image/lunar.png';
import Image7 from '../image/tgcan.png';
import Image8 from '../image/About us.png';
import Image9 from '../image/Contact.png';
import Image10 from '../image/History object2.png';
import Image11 from '../image/Main page.png';
import Image12 from '../image/Portfolio.png';
import Image13 from '../image/Price.png';
import Image14 from '../image/Sale.png';
import Image15 from '../image/pgs.png';
import Image16 from '../image/pgs2.png';
import Image17 from '../image/pgs3.png';
import Image18 from '../image/pgs4.png';
import Image19 from '../image/pgs5.png';
import Image20 from '../image/arenda.png';
import Image21 from '../image/iPhone 14 & 15 Pro Max - 1.png';
import Image22 from '../image/iPhone 14 & 15 Pro Max - 2.png';
import Image23 from '../image/pgs6.png';
import Image24 from '../image/rekl.png';
import Image25 from '../image/History object.png';
import Image26 from '../image/MacBook Pro 16_ - 2.png';
import Image27 from '../image/lllabl.png';
import Image28 from '../image/lllabl2.png';
import Image29 from '../image/big.png';
import Image30 from '../image/Frame 329.png';
import Image31 from '../image/cart.png';
import Image32 from '../image/accept.png';
import Image33 from '../image/Catalog.png';
import Image34 from '../image/Catalog2.png';
import Image35 from '../image/Home.png';
import Image36 from '../image/MacBook Pro 16_ - 18.png';
import Image37 from '../image/et.jpg';
import Image38 from '../image/iPhone 13 mini - 1.jpg';
import Image39 from '../image/Pop-up open.png';
import Image40 from '../image/MacBook Pro 16_ - 30.png';
import Image41 from '../image/robot (2).png';
import Image42 from '../image/ab-tv.png';
import Image43 from '../image/Intro.png';
import Image44 from '../image/Intro2.png';
import Image45 from '../image/Intro3.png';



const ProjectPage = () => {
    const { id } = useParams();
    const [project, setProject] = useState(null);

    const projectData = {
        1: {
            title: 'ABrosko Web-Agency --- Редизайн начинающей студии ABrosko',
            title2: 'ABrosko Web-Agency',
            description: 'Я работал в этой веб студии с самого старта, и занимал должность руководителя проектов. В свобоное время я разработал этот редизайн, он так же был написан на React.js, а дизайн был нарисован в FIGMA. Первую версию дизайна - текущую рисовал так же я, и активно учавствовал в разработках.',
            additionalDescription: 'Заказчик - Александр ABrosko.',
            images: [
                Image1,Image2,Image3,Image4,Image5,
            ],
        },
        2: {
            title: 'Lunar rust - логотип',
            title2: 'Lunar rust',
            description: 'Разработка логотипа для новых модовых проектов серверов. Игра Rust',
            additionalDescription: 'Заказчик - Григорьев Кирилл Александрович.',
            images: [
                Image6
            ],
        },
        // Добавьте больше проектов...
        3: {
            title: 'IT.News - лендинг с модальными окнами',
            title2: 'IT.News',
            description: 'Разработка сайта в формате Landing Page, для новостного тг канала.',
            additionalDescription: 'Заказчик - тг канал BOWDE DESIGN.',
            images: [Image7,
            ],
        },
        4: {
            title: 'FND12 - сайт по продаже строительных услуг',
            title2: 'FND12',
            description: 'Со мной связались по сарафанному радио для разработки сайта под ключ. Сайт разработан на HTML5&CSS3, форма PHP, посадкой на хостинг занимался я, хостинг Beget.',
            additionalDescription: 'Заказчик - FND12.',
            images: [
                Image8,Image9,Image10,Image11,Image12,Image13,Image14,
            ],
        },
        5: {
            title: 'ПГС - Партнерство группы самозанятых',
            title2: 'ПГС',
            description: 'Разработка сайта под ключ, формат - Online Market. Сайт по продаже строй материалов, а так же аренды строительной техники',
            additionalDescription: 'ПГС.',
            images: [
                Image15,Image16,Image17,Image18,Image19,Image20,Image21,Image22,Image23
            ],
        },
        6: {
            title: 'Креатив - Рекламное агенство г.Псков',
            title2: 'Креатив',
            description: 'Разработка сайта в формате Landing Page(+1 page), только дизайн.',
            additionalDescription: 'Креатив - Рекламное агенство г.Псков',
            images: [ Image24,Image25
            ],
        },
        7: {
            title: 'Набор дизайн-прототипов для студентки университета.',
            title2: 'Дизайн-шаблоны',
            description: 'Отрисовка шаблонов на заказ.',
            additionalDescription: 'Дарья',
            images: [ Image27, Image28, Image29
            ],
        },
        8: {
            title: 'About.me - сайт для 3D художницы',
            title2: 'About.me',
            description: 'Отрисовка и верстка сайта с портфолио для художницы.',
            additionalDescription: 'Не получил разрешение на оглашения имени заказчика',
            images: [Image26
            ],
        },
        9: {
            title: 'Логотип - ABrosko',
            title2: 'ABrosko',
            description: 'Разработка логотипа для студии ABrosko.',
            additionalDescription: 'Александр ABrosko',
            images: [ Image30
            ],
        },
        10: {
            title: 'YDVS PRT',
            title2: 'YDVS PRT',
            description: 'Дипломная работа на курсах yudaev.school январь -2023.',
            additionalDescription: 'Курсы yudaev.school',
            images: [ Image35,Image34,Image33,Image32,Image31,
            ],
        },
        11: {
            title: 'PS5 - Landing',
            title2: 'PS5',
            description: 'Работа для оценки своих навыков в дизайне и верстке (Март 2023).',
            additionalDescription: 'Оценка навыков',
            images: [ Image36
            ],
        },
        12: {
            title: 'MR.Robot - 1 screen',
            title2: 'MR.Robot',
            description: 'Работа для набивки портфолио.',
            additionalDescription: 'MR.Robot',
            images: [ Image41
            ],
        },
        13: {
            title: 'MoStudio Visual - Тестовое задание в студию, отрисовка логотипа',
            title2: 'MoStudio Visual',
            description: 'Тестовое задание для студии MoStudio Visual на авито.',
            additionalDescription: 'MoStudio Visual',
            images: [ Image40
            ],
        },
        14: {
            title: 'ET.WEB - под ключ',
            title2: 'ET.WEB',
            description: 'Один из первых заказов под ключ, заказчик с fl.ru.',
            additionalDescription: 'Анна',
            images: [ Image37,Image38,Image39
            ],
        },
        15: {
            title: 'ABrosko AB-tv --- Новый проект студии ABrosko',
            title2: 'ABrosko Web-Agency',
            description: 'Я работал в этой веб студии с самого старта, и занимал должность руководителя проектов. В свобоное время я разработал этот редизайн, он так же был написан на React.js, а дизайн был нарисован в FIGMA. Первую версию дизайна - текущую рисовал так же я, и активно учавствовал в разработках.',
            additionalDescription: 'Заказчик - Александр ABrosko.',
            images: [ Image42,Image43,Image44,Image45
            ],
        },
        // И так далее...
    };

    useEffect(() => {
        const project = projectData[id];
        if (project) {
            setProject(project);
        } else {
            setProject({ title: 'Проект не найден',title2:'', description: '', additionalDescription: '', images: [] });
        }
    }, [id]);

    if (!project) {
        return <p>Загрузка...</p>;
    }

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true,
    };


    return (
        <div className="project-page">

            <h1>{project.title}</h1>
            <div className="project-slider">
                <Slider {...sliderSettings}>
                    {project.images.map((img, index) => (
                        <div key={index}>
                            <img src={img} alt={`Slide ${index}`} />
                        </div>
                    ))}
                </Slider>
            </div>
            <div className="project-footer">
                <h2>{project.title2}</h2>
                <hr/>
                <p>{project.description}</p>
                <hr/>
                <p>{project.additionalDescription}</p>
            </div>
        </div>
    );
};

export default ProjectPage;
