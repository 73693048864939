import React, { useState } from 'react';
import './FAQ.css';

const FAQ = () => {
    const [activeIndexes, setActiveIndexes] = useState([]);

    const faqs = [
        { question: 'В каком формате происходит оплата?', answer: '40-20-40 --- Оплата происходит поэтапно: Предоплата, промежуточная, конец разработки.' },
        { question: 'С кем вы готовы сотрутничать?', answer: 'Работаю и заключаю договора со всеми, начиная от самозанятых и ИП, заканчивая фирмами и ООО.' },
        { question: 'С какими CMS вы работаете?', answer: 'Я работаю с такими CMS, как: WordPress, OpenCart, Drupal.' },
        { question: 'Как быстро вы сдаете проекты?', answer: 'Все зависит от типа заказа, как правило, не более недели.' },
        { question: 'Какими технологиями вы пользуетесь при разработке?', answer: 'Основная масса заказов написана на React, но я готов выполнить заказ на любых желаемых вами языках, фреймфорках или предпроцессорах.' }
    ];

    const handleClick = index => {
        if (activeIndexes.includes(index)) {
            setActiveIndexes(activeIndexes.filter(i => i !== index));
        } else {
            if (activeIndexes.length >= 2) {
                setActiveIndexes([activeIndexes[1], index]);
            } else {
                setActiveIndexes([...activeIndexes, index]);
            }
        }
    };

    return (
        <section id="faq" className="faq-section"  data-aos="fade-up">
            <h2 >FAQ's</h2>
            <div className="faq-container">
                {faqs.map((faq, index) => (
                    <div key={index} className={`faq-item ${activeIndexes.includes(index) ? 'active' : ''}`} onClick={() => handleClick(index)}>
                        <div className="faq-question" >
                            {faq.question}
                            <span className="faq-icon">{activeIndexes.includes(index) ? '-' : '+'}</span>
                        </div>
                        <div className={`faq-answer ${activeIndexes.includes(index) ? 'show' : ''}`}>
                            {faq.answer}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default FAQ;
